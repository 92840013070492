// src/App.js
import React from 'react';
import Main from '../src/pages/main/Main';
import RainingIcons from './components/rainingIcons/RainingIcons'; // Import RainingIcons globally
import './App.css';

function App() {
  return (
    <div className="app">
      <RainingIcons /> {/* Make this a global background */}
      <Main />
    </div>
  );
}

export default App;
