import React, { useEffect, useState } from 'react';
import './rainingIcons.css';
import icon from '../../assests/LMIconLogoOrng.png';

function RainingIcons() {
  const [icons, setIcons] = useState([]);

  useEffect(() => {
    const iconElements = [];
    for (let i = 0; i < 50; i++) { // Increase the number of icons if needed
      const leftPosition = Math.random() * 100; // Random left position
      const delay = Math.random() * 10; // Random delay
      iconElements.push(
        <img
          key={i}
          src={icon}
          alt="Raining Icon"
          className="raining-icon"
          style={{ left: `${leftPosition}%`, animationDelay: `${delay}s` }}
        />
      );
    }
    setIcons(iconElements);
  }, []);

  return <div className="raining-icon-background">{icons}</div>;
}

export default RainingIcons;
