// src/pages/Main.jsx
import React from 'react';
import Header from '../../components/header/Header';
import Content from '../../components/content/Content';
import './main.css';
import RainingIcons from '../../components/rainingIcons/RainingIcons';

function Main() {
  return (
    <div className="main">
      <RainingIcons /> {/* Raining icons as background */}
      <div className="content-container">
        <Header />
        <Content />
      </div>
    </div>
  );
}

export default Main;
