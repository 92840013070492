import React from 'react';
import './content.css';

function Content() {
  return (
    <section className="content">
      <h2>Join the Revolution in Meme Coins! 🚀</h2>
      <p>
        Loot Markets is here to redefine the way you create, buy, and sell meme coins, and we're inviting YOU to be at the forefront of this game-changing platform. 🎉
      </p>
      <h2>
        Imagine a world where you can:
      </h2>
      <ul>
        <li>💡 <strong>Create</strong> and launch your own meme coins on bonding curves, effortlessly.</li>
        <li>💰 <strong>Bet on market caps</strong> and watch your creations soar across decentralized networks.</li>
        <li>🌍 <strong>Manage memes across chains</strong> while connecting with a vibrant community of meme enthusiasts.</li>
      </ul>
      <p>
        By signing up for our exclusive beta, you'll gain early access to cutting-edge features and be a part of shaping the future of Web3 meme coin trading. Don't miss out on the chance to influence the next big thing in decentralized marketplaces. 💥
      </p>
      <p>
        Get ready to revolutionize meme coin trading—Loot Markets is launching soon! 🔥
      </p>
      <a
        href="https://forms.gle/F2UmRqiPVdXh3SaE8"
        target="_blank"
        rel="noopener noreferrer"
        className="beta-button"
      >
        Sign Up for the Beta
      </a>
    </section>
  );
}

export default Content;